import React, {
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect, useStore } from 'react-redux'
import { Dialog, Slide } from '@material-ui/core'
import { Helmet } from 'react-helmet'

import {
  CARDAPIO_CARD,
  IMAGE_ELEVATE,
  WHATSAPP,
  WHATSAPP_NUMBER
} from '../../config'

import WhattsAppImage from '../assets/whatsapp_icone.png'

import {
  useStoreSettings,
  useCustomLayout,
  useDeviceLayout,
  useLayout,
  useSidebar,
  useScroll
} from '../hooks'

import {
  menuActions,
  modality,
  store
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity,
  calculateCartTotal
} from '../../domain/helpers'

import history from '../../history'

import {
  addProduct,

  handleGTM,

  fetchStoreFees,

  removeProduct,
  removeSelectedOldCategory,
  removeSelectedOldProduct,
  removeSelectedProductsCategories,

  //setSelectedCategory,
  setSearch,
  setShowSignUp,
  setDirectAccess
} from '../../redux/actions/main'

import {
  DefaultImage as Image
} from '../components'

import {
  ProductCard,
  Cards,
  Cart,
  CartContainer,
  Skeleton,
  MainHeader,
  Navigation,
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide,
  OrderTracking,
  Menu,
  Search,
  CategoriesProducts,
  CategoriesProductsCard,
  CategoriesSelect,
  Categories,
  CartBar,
  StatusOverlay
} from '..'

import {
  Header,
  FixedHeader
} from '.'

import {
  Wrapper,
  Paragraph,
  LoadingWrapper,
  SubHeaderWrapper,
  WhattsAppImg
} from './styles.js'
import { Footer } from './Footer'
import { Product } from '../product'
import { AppDownload } from './AppDownload'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

function StorePage(props) {
  const store = useStore()

  const loading = store.getState().loading.loading || []
  const address = store.getState().address.address || {}
  const latitude = address.latitude || null
  const longitude = address.longitude || null
  const addressMain = address.main || null

  const {
    aisle,
    accessToken,
    allCategories,
    categories,
    cartProducts,
    device,
    modalityId,
    oldSelectedProduct,
    oldSelectedCategory,
    products,
    selectedCategory,
    selectedProduct,
    selectedProductsCategories,
    storeName,
    storeImg,
    storeStatus,
    theme,
    userName,
    addProduct,
    location,
    match,
    deliveryMinimumValue,
    pickupMinimumValue,
    urlAddress,
    URLParameters,

    fetchStore,
    fetchStoreFees,

    handleGTM,

    removeProduct,
    removeSelectedOldProduct,

    setCategories,
    setModality,
    setSelectedCategory,
    setSelectedProduct,
    setShowSignUp,
    setStoreFees,
    removeSelectedProductsCategories,
    cleanStore,
    modality,
    setDirectAccess,
    setUrlAddress,
		minForFreeShipping,
		deliveryFee
  } = props

  const {
    main
  } = theme

  const storeHeaderImage = theme.headerImage
  const storeHeaderImageMobile = theme.headerImageMobile
  const storeLogo = theme.logoImageSingin
  const colorPrimary = theme.main.general.backgroundMain

  const {
    general
  } = main

  const [productURL, setProductURL] = useState((match.params && match.params.productURL) || null)
  const [noResults, setNoResults] = useState(false)
  const [cartDisplay, setCartDisplay] = useState(false)
  const [cartSlideDown, setCartSlideDown] = useState(false)
  const [storeContentMaxHeight, setStoreContentMaxHeight] = useState(0)
  const [visibleNavigation, setVisibleNavigation] = useState(false)
  const [visibleCategories, setVisibleCategories] = useState(false)
  const [visibleCategoriesCard, setVisibleCategoriesCard] = useState(false)
  const [viewCards, setViewCards] = useState(false)
  const [activeDialogSignIn, setActiveDialogSignIn] = useState(false)
  const [newCard, setNewCard] = useState(false)
  const [dialogStatus, setDialogStatus] = useState(null)
  const [overlay, setOverlay] = useState(false)
  const [cartTotal, setCartTotal] = useState(0)
  const [appDownload, setAppDownload] = useState(false)

  const scroll = useScroll()

  const {
    setScroll,
    scrollY
  } = scroll

  const {
    layoutMode,
    layoutModeEnum,
    handleLayoutMode
  } = useLayout()

  const {
    sidebarContent,
    sidebarOpened,
    sidebarContentEnum,
    handleSidebarContent,
    handleSidebarOpened
  } = useSidebar()

  const {
    url,
    title,
    description
  } = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  useCustomLayout({
    device,
    section: 'store',
    styles: general,
    backgroundColor: '#fff'
  })

  const menuProps = {
    storeName,
    storeLogo,
    storeImg,
    modalityId,
    selectedCategory,
    direction: (CARDAPIO_CARD === 'true' ? 'card' : (aisle ? 'horizontal' : 'vertical')),
    status: storeStatus,
    opened: sidebarOpened,
    setStoreContentMaxHeight,
    setNoResults
  }
  useEffect(() => {
    if (!!modality) {
      setModality({ id: 2 })
      setDirectAccess(true)
    }
    //cleanStore()
  }, [
    setDirectAccess,
    setModality,
    cleanStore,
    modality
  ])

  useEffect(() => {
    const { referrer } = document
    const matchProductURL = referrer.match(/\/store\//g) || []
    const fromProductView = !!matchProductURL.length

    if (!productURL && !fromProductView && !scrollY) {
      //Removi o setTimeout que fazia alguma coisa que não sei. Validar
      fetchStore([{
        name: 'store',
        type: 'error',
        callback: () => {
          console.warn('Store.getStore.fetchStore.error')

          if (scrollY) {
            window.scrollTo(0, scrollY + 'px')
          }
        }
      }, {
        name: 'store',
        type: 'success',
        callback: async () => {
          if (scrollY) {
            window.scrollTo(0, scrollY + 'px')
          }
        }
      }, {
        name: 'store',
        type: 'data',
        data: {
          id: 'store',
          delivery: true,
          force: window.performance.navigation.type === 1
        }
      },
      {
        name: 'store',
        type: 'modality',
        data: {
          pickup: modality
        }
      }])
    }
  }, [
    modality,
    fetchStore,
    selectedProduct,
    productURL,
    scrollY,
    storeStatus
  ])

  useEffect(() => {
    if (addressMain && latitude && longitude) {
      fetchStoreFees([{
        type: 'error',
        name: 'store-fees',
        callback: () => { }
      }, {
        type: 'success',
        name: 'store-fees',
        callback: () => { }
      }])
    }
  }, [
    fetchStoreFees,
    addressMain,
    latitude,
    longitude
  ])

  useEffect(() => {
    setCartTotal(calculateCartTotal(products))
  }, [
    products
  ])

  useEffect(() => {
    setProductURL((match.params && match.params.productURL) || null)
    setSelectedProduct(null)
  }, [
    match,
    setSelectedProduct
  ])

  useEffect(() => {
    if (!!productURL && !selectedProduct) {
      const selectedCategoryByProduct = categories.map((mappedItem) => {
        const newMappedItem = mappedItem.products && mappedItem.products.map((mappedItem) => {
          const productURLName = mappedItem.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-')

          return {
            urlName: productURLName,
            ...mappedItem
          }
        })

        const searchResult = newMappedItem.filter((mappedItem) => {
          if (mappedItem.urlName === productURL) {
            return true
          } else {
            return false
          }
        })

        return searchResult.length ? {
          id: mappedItem.id,
          name: mappedItem.name,
          products: newMappedItem.filter(filteredItem => filteredItem.urlName === productURL)
        } : null
      }).filter(filteredItem => !!filteredItem)

      if (selectedCategoryByProduct[0] && selectedCategoryByProduct[0].products && selectedCategoryByProduct[0].products[0]) {
        setSelectedProduct({
          ...selectedCategoryByProduct[0].products[0],
          quantity: 1,
          category: {
            name: selectedCategoryByProduct[0].name,
            id: selectedCategoryByProduct[0].id
          },
          selectedAdditionals: [],
					isNewAddition: true
        })
        setSelectedCategory({
          name: selectedCategoryByProduct[0].name,
          id: selectedCategoryByProduct[0].id
        })
      }

      return
    }

    if (selectedProduct && !productURL) {
      setSelectedProduct(null)

      return
    }
  }, [
    productURL,
    setSelectedProduct,
    selectedProduct,
    setSelectedCategory,
    categories,
    products
  ])

  useEffect(() => {
    setShowSignUp(false)

    return () => { }
  }, [
    setShowSignUp
  ])

  useEffect(() => {
    if (oldSelectedProduct && oldSelectedProduct.id) {
      const prevProductElement = Array.from(document.querySelectorAll('#store-products > div > div > div > div > h2')).find(element => !!(element.textContent.trim() === oldSelectedProduct.name.trim()))
      const scrollTop = (prevProductElement && prevProductElement.getBoundingClientRect().top - (window.innerHeight / 2))

      scrollTop && window.scroll(0, scrollTop)
    }

    return () => { }
  }, [
    oldSelectedProduct
  ])

  useEffect(() => {
    if (oldSelectedCategory && oldSelectedCategory.name) {
      const prevProductElement = Array.from(document.querySelectorAll('#store-products > div')).find(element => element.getAttribute('name').trim() === oldSelectedCategory.name.trim())
      const scrollTop = (prevProductElement && prevProductElement.offsetTop)

      scrollTop && window.scroll(0, scrollTop)
    }

    return () => { }
  }, [
    oldSelectedCategory
  ])

  const cartHelper = {
    handleClose: () => {
      const storeQuerySelector = document.querySelector('#store-content')
      const maxHeight = storeQuerySelector && parseInt(storeQuerySelector.offsetHeight)

      setStoreContentMaxHeight(maxHeight)
      setCartSlideDown(true)

      setTimeout(() => {
        setCartDisplay(false)
        setCartSlideDown(false)
      }, 500)
    }
  }

  function handleNoToken() {
    setActiveDialogSignIn(true)

    setTimeout(() => {
      setActiveDialogSignIn(false)
    }, 3000)
  }

  function handleSelectedProductCategories(args) {
    removeSelectedOldProduct()
    setSelectedCategory(args)
  }

  function handleCloseDialog() {
    setScroll({ y: window.scrollY })
    history.push('/')
  }

  function handleCloseDialogNavigation() {
    setVisibleNavigation(false)
  }

  function handleCloseDialogCategories() {
    setVisibleCategories(false)
  }

  function handleCloseDialogCategoriesCard() {
    setVisibleCategoriesCard(false)
  }

  function handleCloseDialogAppDownload() {
    setAppDownload(false)
  }

  async function handleModality(id) {
    setModality({ id })
    setStoreFees(null)

    if (id === 4) {
      await fetchStore([{
        name: 'store',
        type: 'error',
        callback: () => {
          console.warn('Store.handleModality(4).fetchStore.error')
        }
      }, {
        name: 'store',
        type: 'success',
        callback: () => { }
      }, {
        name: 'store',
        type: 'data',
        data: {
          delivery: true
        }
      }])

      if (address && address.main && address.latitude && address.longitude) {
        await fetchStoreFees([{
          type: 'error',
          name: 'store-fees',
          callback: () => {

          }
        }, {
          type: 'success',
          name: 'store-fees',
          callback: () => {

          }
        }])
      }
    } else {
      await fetchStore([{
        name: 'store',
        type: 'error',
        callback: () => {
          console.warn('Store.handleModality(2).fetchStore.error')
        }
      }, {
        name: 'store',
        type: 'success',
        callback: () => { }
      }, {
        name: 'store',
        type: 'data',
        data: {}
      }])
    }
  }

  function handleViewProduct(args) {
    const productURL = args.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/ /g, '-')

    //GTM 3.0 - Evento de Visualizar produto do carrrinho
    const dataGTM = {
      'event': `view_item`,
      'ecommerce': {
        'currency': 'BRL',
        'store': storeName,
        'items': [{
          'item_name': args.name,
          'item_id': args.id,
          'price': args.promotionalValue ? args.promotionalValue : args.value,
          'discount': null,
          'categoria': (args.category && args.category.name) ?? null,
          'quantity': 1
        }]
      }
    }
    console.warn(dataGTM)
    handleGTM(dataGTM)

    history.push(`/store/${productURL}`)
  }

  function handleCategory(args) {
    const {
      name
    } = args

    const categoryElement = document.getElementById(`store-products-category-${name}`)

    if (categoryElement) {
      window.scroll(0, categoryElement.offsetTop - 140)

      setSelectedCategory(args)
    }
  }

  async function handleAddProduct(product) {
    if (storeStatus !== 1) {
      handleStatusNotification()

      return
    }
    await removeProduct(product)
    await addProduct(product)

    handleLayoutMode(layoutModeEnum.sideFullContent)
    handleSidebarContent(sidebarContentEnum.cart)
    handleSidebarOpened(true)

    //GTM 3.0 - Evento de Adicionar produto ao carrrinho
    const dataAddProduct = {
      'event': `add_to_cart`,
      'ecommerce': {
        'currency': 'BRL',
        'store': storeName,
        'items': [{
          'item_name': product.name,
          'item_id': product.id,
          'price': product.promotionalValue ? product.promotionalValue : product.value,
          'discount': null,
          'categoria': product.categoryName ?? null,
          'quantity': 1
        }]
      }
    }
    handleGTM(dataAddProduct)
    //console.warn({ dataAddProduct })
  }

  function handleStatusNotification() {
    setOverlay(true)
    setDialogStatus({
      status: storeStatus,
      icon: true,
      actions: [{
        label: 'Entendi!',
        handleClick: () => {
          setOverlay(false)
          setDialogStatus(null)
        }
      }]
    })
  }

  function handleSearchCategories(searchTerm) {
    const filteredCategories = allCategories && allCategories.length
      && allCategories.filter(filteredItem => filteredItem.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(searchTerm.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1)

    if (filteredCategories.length && searchTerm !== 'all') {
      setStoreContentMaxHeight(0)

      setCategories(filteredCategories)
      setNoResults(false)

      return
    }

    setStoreContentMaxHeight(0)
    setCategories(allCategories)
    setNoResults(true)
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleNewCard() {
    if (!newCard) {
      setNewCard(true)
    } else {
      setNewCard(false)
    }
  }

  function handleWhattsApp() {
    let otherWindow = window.open()
    otherWindow.opener = null
    otherWindow.location = `http://api.whatsapp.com/send?1=pt_BR&phone=${WHATSAPP_NUMBER}`
  }

  return <Wrapper id={'store'} maxHeight={storeContentMaxHeight}>
    <Helmet>
      <title>{`${title} - Cardápio`}</title>
      <meta name='description' content={description} />
      <link rel='canonical' href={`${url}/store`} />
    </Helmet>

    <PageContainer
      isSideOpen={sidebarOpened}
      mode={layoutMode}
    >
      <PageContainerHeader>
        {loading.includes('store') && !isMobile && <LoadingWrapper>
          <Skeleton module='header' />
        </LoadingWrapper>}

        {!loading.includes('store') && <MainHeader
          mode={isMobile ? 'mobile' : 'full'}
          urlAddress={urlAddress}
          setUrlAddress={setUrlAddress}
          theme={theme}
          name={storeName}
          modalityId={modalityId}
          handleModality={handleModality}
          storeLogo={storeLogo}
          storeImg={storeImg}
          userName={userName}
          accessToken={accessToken}
          activeDialogSignIn={activeDialogSignIn}
          cartItemsQuantity={caculateProductsQuantity(cartProducts)}
          pathName={location.pathname}
          homeOnClick={() => {
            handleLayoutMode(layoutModeEnum.default)
            handleSidebarContent(sidebarContentEnum.default)
            handleSidebarOpened(false)
            setViewCards(false)
          }}
          userClicked={() => {
            if (sidebarOpened && sidebarContent === sidebarContentEnum.nav) {
              handleLayoutMode(layoutModeEnum.default)
              handleSidebarContent(sidebarContentEnum.default)
              handleSidebarOpened(false)
            } else {
              handleLayoutMode(layoutModeEnum.headerFullContent)
              handleSidebarContent(sidebarContentEnum.nav)
              handleSidebarOpened(true)
            }
          }}
          cartClicked={() => {
            if (sidebarOpened && sidebarContent === sidebarContentEnum.cart) {
              handleLayoutMode(layoutModeEnum.default)
              handleSidebarContent(sidebarContentEnum.default)
              handleSidebarOpened(false)
            } else {
              handleLayoutMode(layoutModeEnum.sideFullContent)
              handleSidebarContent(sidebarContentEnum.cart)
              handleSidebarOpened(true)
            }
          }}
          isSidebarOpen={sidebarOpened}
        />}
      </PageContainerHeader>
      <PageContainerMain opened={sidebarOpened}>
        {!viewCards && <>
          {loading.includes('store') && <Skeleton module={isMobile ? 'store-mobile' : 'store'} />}

          {!loading.includes('store') && <>
            {WHATSAPP === 'true' &&
              <WhattsAppImg className={isMobile && cartProducts.length > 0 ? 'ativo' : sidebarContent === layoutModeEnum.sideFullContent ||
                layoutMode === layoutModeEnum.headerFullContent ? 'open-cart' : ''} onClick={() => handleWhattsApp()}>
                <Image src={WhattsAppImage} alt="" />
              </WhattsAppImg>}

            <Header
              handleModality={handleModality}
              status={storeStatus}
              image={storeHeaderImage}
              imageMobile={storeHeaderImageMobile}
              setVisibleNavigation={setVisibleNavigation}
              isMenu={true}
              setAppDownload={setAppDownload}
            />

            {isMobile && !cartDisplay && <Categories
              data={categories}
              handleCategory={handleCategory} />}

            {viewCards ? null : <SubHeaderWrapper>
              <CategoriesSelect
                data={allCategories}
                handleSearchCategories={handleSearchCategories}
              />
              <Search
                setStoreContentMaxHeight={setStoreContentMaxHeight}
                setNoResults={setNoResults}
              />
            </SubHeaderWrapper>}

            {!viewCards && <OrderTracking />}

            {noResults && !storeName ? <Paragraph>Nenhum produto foi encontrado.</Paragraph> :
              <>
                {isMobile && !cartDisplay && <FixedHeader name={storeName} />}

                {!viewCards && <Menu
                  {...menuProps}
                  handleViewProduct={handleViewProduct}
                  handleAddProduct={handleAddProduct}
                  handleGTM={handleGTM}
                  handleSelectedProductCategories={handleSelectedProductCategories}
                  handleSelectedCategory={setSelectedCategory}
                  selectedProductsCategories={selectedProductsCategories}
                  handleSearchCategories={handleSearchCategories}
                  setVisibleCategories={setVisibleCategories}
                  setVisibleCategoriesCard={setVisibleCategoriesCard}
                />}
              </>
            }
            {isMobile && (!selectedProduct && !cartDisplay && !viewCards) ?
              <CartBar
                handleClick={() => {
                  history.push(`/cart${URLParameters}`)
                }} /> :
              null
            }
            <Footer />
          </>
          }
        </>}

        {!!viewCards && <>
          <Image src={storeHeaderImage} alt="" />

          <Cards
            setViewCards={setViewCards}
            payment={true}
            newCard={newCard}
            handleNewCard={handleNewCard}
          />
        </>}

        {dialogStatus && <StatusOverlay
          overlay={overlay}
          setOverlay={setOverlay}
          status={dialogStatus.status}
          icon={dialogStatus.icon}
          actions={dialogStatus.actions}
          isMobile={isMobile}
        />}
      </PageContainerMain>
      <PageContainerSide opened={sidebarOpened}>
        {!isMobile && layoutMode === layoutModeEnum.sideFullContent &&
          <CartContainer
            logo={storeLogo}
            storeImg={storeImg}
            name={storeName}
            cartTotal={cartTotal}
						minForFreeShipping={minForFreeShipping}
						deliveryFee={deliveryFee}
            modalityId={modalityId}
            minimumValue={modalityId === 2 ? pickupMinimumValue : deliveryMinimumValue}
            cartClicked={() => {
              handleLayoutMode(layoutModeEnum.sideFullContent)
              handleSidebarContent(sidebarContentEnum.cart)
              handleSidebarOpened(true)
            }}
          >
            <Cart
              mode='simple'
              setViewCards={setViewCards}
              viewCards={viewCards}
              className={cartSlideDown ? 'slide-down' : ''}
              handleFinish={() => {
                const storeQuerySelector = document.querySelector('#cart-content')
                const maxHeight = storeQuerySelector && parseInt(storeQuerySelector.offsetHeight)

                setStoreContentMaxHeight(maxHeight)
              }}
              handleClose={cartHelper.handleClose}
              handleNoToken={handleNoToken}
            />
          </CartContainer>
        }
        {layoutMode === layoutModeEnum.headerFullContent &&
          <Navigation
            contactUs={() => handleContactUs()}
            userName={userName}
            currentRoute='store'
            userClicked={() => {
              handleLayoutMode(layoutModeEnum.sideFullContent)
              handleSidebarContent(sidebarContentEnum.cart)
              handleSidebarOpened(true)
            }}
          />
        }
      </PageContainerSide>

      {CARDAPIO_CARD === 'true' ? <Dialog
        id='product-view-dialog'
        onClose={() => handleCloseDialog()}
        aria-labelledby='product-view-dialog'
        open={!!selectedProduct}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        scroll={'body'}
        className={`product-detail ${selectedProduct && selectedProduct.additionals &&
          selectedProduct.additionals.length ? 'has-additionals' : ''} ${isMobile ? 'mobile' : ''}`}
        PaperProps={{
          elevation: 0,
          style: {
            maxWidth: isMobile ? 'unset' : (selectedProduct && selectedProduct.additionals &&
              selectedProduct.additionals.length ? '40rem' : '24rem'),
            minWidth: isMobile ? 'calc(100% - 3rem)' : (selectedProduct && selectedProduct.additionals &&
              selectedProduct.additionals.length ? '40rem' : '24rem'),            
            margin: isMobile ? '5px' : '',            
            backgroundColor: colorPrimary
          }
        }}
      >
        <ProductCard
          handleClose={handleCloseDialog}
          handleStatusNotification={handleStatusNotification}
          cartClicked={() => {
            handleLayoutMode(layoutModeEnum.sideFullContent)
            handleSidebarContent(sidebarContentEnum.cart)
            handleSidebarOpened(true)
          }}
        />
      </Dialog> : <Dialog
        id='product-view-dialog'
        onClose={() => handleCloseDialog()}
        aria-labelledby='product-view-dialog'
        open={!!selectedProduct}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        scroll={'body'}
        className={`product-detail ${selectedProduct && selectedProduct.additionals &&
          selectedProduct.additionals.length ? 'has-additionals' : ''} ${isMobile ? 'mobile' : ''}`}
        PaperProps={{
          elevation: 0,
          style: {
            maxWidth: isMobile ? 'unset' : (selectedProduct && selectedProduct.additionals &&
              selectedProduct.additionals.length ? '40rem' : '24rem'),
            minWidth: isMobile ? 'calc(100% - 3rem)' : (selectedProduct && selectedProduct.additionals &&
              selectedProduct.additionals.length ? '40rem' : '24rem'),            
            margin: isMobile ? '5px' : '',
            backgroundColor: colorPrimary
          }
        }}
      >
        <Product
          handleClose={handleCloseDialog}
          cartClicked={() => {
            handleLayoutMode(layoutModeEnum.sideFullContent)
            handleSidebarContent(sidebarContentEnum.cart)
            handleSidebarOpened(true)
          }}
        />
      </Dialog>}

      <Dialog
        onClose={() => handleCloseDialogCategories()}
        aria-labelledby='categories-dialog'
        open={visibleCategories}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        className={`categories-detail`}
      >
        <CategoriesProducts
          removeSelectedProductsCategories={removeSelectedProductsCategories}
          handleViewProduct={handleViewProduct}
          handleAddProduct={handleAddProduct}
          close={handleCloseDialogCategories}
        />
      </Dialog>

      <Dialog
        onClose={() => handleCloseDialogCategoriesCard()}
        aria-labelledby='categories-card-dialog'
        open={visibleCategoriesCard}
        TransitionComponent={Transition}
        maxWidth={'sm'}
      >
        <CategoriesProductsCard
          removeSelectedProductsCategories={removeSelectedProductsCategories}
          handleViewProduct={handleViewProduct}
          handleAddProduct={handleAddProduct}
          close={handleCloseDialogCategoriesCard}
          storeLogo={storeLogo}
        />
      </Dialog>

      <Dialog
        onClose={() => handleCloseDialogAppDownload()}
        aria-labelledby='app-download-dialog'
        open={appDownload}
        TransitionComponent={Transition}
        maxWidth={'sm'}
      >
        <AppDownload />
      </Dialog>

      {isMobile && <Dialog
        onClose={() => handleCloseDialogNavigation()}
        aria-labelledby='navigation-dialog'
        open={visibleNavigation}
        maxWidth={'xs'}
        TransitionComponent={Transition}
      >
        <Navigation
          contactUs={() => handleContactUs()}
          userName={userName}
          currentRoute='store'
          userClicked={() => {
            handleLayoutMode(layoutModeEnum.headerFullContent)
            handleSidebarContent(sidebarContentEnum.cart)
            handleSidebarOpened(true)
          }}
          setVisibleNavigation={setVisibleNavigation}
        ></Navigation>
      </Dialog>}
    </PageContainer>
  </Wrapper>
}

StorePage.propTypes = {
  aisle: PropTypes.bool,
  allCategories: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  device: PropTypes.string,
  modalityId: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  search: PropTypes.shape({
    text: PropTypes.string
  }),
  selectedCategory: PropTypes.shape({
    name: PropTypes.string
  }),
  selectedProduct: PropTypes.shape({
    additionals: PropTypes.arrayOf(PropTypes.shape({}))
  }),
  storeLogo: PropTypes.string,
  storeName: PropTypes.string,
  storeStatus: PropTypes.number,
  userName: PropTypes.string,

  addProduct: PropTypes.func,

  fetchStore: PropTypes.func,
  fetchStoreFees: PropTypes.func,

  handleGTM: PropTypes.func,

  removeProduct: PropTypes.func,
  removeSelectedOldCategory: PropTypes.func,
  removeSelectedOldProduct: PropTypes.func,
  removeSelectedProduct: PropTypes.func,

  setCategories: PropTypes.func,
  setModality: PropTypes.func,
  setQuantity: PropTypes.func,
  setSelectedCategory: PropTypes.func,
  setSelectedProduct: PropTypes.func,
  setSearch: PropTypes.func,
  removeSelectedProductsCategories: PropTypes.func,
  accessToken: PropTypes.string,
  cartProducts: PropTypes.arrayOf(PropTypes.shape({})),
  oldSelectedProduct: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  oldSelectedCategory: PropTypes.shape({
    name: PropTypes.string
  }),
  openModalCategory: PropTypes.bool,
  selectedProductsCategories: PropTypes.bool,
  storeImg: PropTypes.string,
  theme: PropTypes.shape({
    headerImage: PropTypes.string,
    headerImageMobile: PropTypes.string,
    logoImageSingin: PropTypes.string,
    colorPrimary: PropTypes.string,
    main: PropTypes.shape({
      general: PropTypes.shape({
        backgroundMain: PropTypes.string
      })
    })
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }),
  userHistory: PropTypes.shape({
    back: PropTypes.string
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      productURL: PropTypes.string
    })
  }),
  URLParameters: PropTypes.string,
  setShowSignUp: PropTypes.func,
  setStore: PropTypes.func,
  setStoreFees: PropTypes.func,
  cleanStore: PropTypes.func,
  setOpenCart: PropTypes.func,
  deliveryMinimumValue: PropTypes.number,
  pickupMinimumValue: PropTypes.number
}

const mapStateToProps = (state) => {
  return {
    aisle: (state.store.store && state.store.store.aisle) || false,
    allCategories: state.menu.allCategories || [],
    categories: state.menu.categories || [],
    selectedProduct: (state.menu.selectedProduct) || null,
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    openModalCategory: state.store.store && state.store.store.selectedCategory && state.store.store.selectedCategory.openModalCategory,
    products: (state.store.store && state.store.store.products) || [],
    selectedCategory: state.store.store && state.store.store.selectedCategory,
    selectedProductsCategories: state.store.store && state.store.store.selectedCategory && state.store.store.selectedCategory.selectedProductsCategories,
    storeName: state.store.store && state.store.store.name,
    storeStatus: state.store.store && state.store.store.status,
    storeImg: (state.store.store && state.store.store.logo) || '',
    oldSelectedProduct: (state.store.store && state.store.store.oldSelectedProduct) || {},
    oldSelectedCategory: (state.store.store && state.store.store.oldSelectedCategory) || {},
    search: state.main.search,
    modalityId: state.main.modality && state.main.modality.id,
    device: (state.main.params && state.main.params.device) || '',
    accessToken: state.user.accessToken,
    userName: state.user.name || '',
    userHistory: state.main.userHistory || {},
    URLParameters: (state.main.URLParameters) || '',
    deliveryMinimumValue: (state.store.store && state.store.store.deliveryMinimumValue) || 0,
    pickupMinimumValue: (state.store.store && state.store.store.pickupMinimumValue) || 0,
		minForFreeShipping: (state.store.store && state.store.store.minForFreeShipping) || null,
		deliveryFee: (state.store.store && state.store.store.deliveryFee) || 0,
  }
}

const GetConnection = connect(mapStateToProps, {
  addProduct,

  fetchStore: store.fetch,
  setStoreFees: store.setStoreFees,
  cleanStore: store.cleanStore,
  fetchStoreFees,

  handleGTM,
  setDirectAccess,

  removeProduct,
  removeSelectedOldCategory,
  removeSelectedOldProduct,

  setCategories: menuActions.setCategories,
  setModality: modality.setModality,
  setSelectedCategory: store.setSelectedCategory,
  setSelectedProduct: menuActions.setSelectedProduct,
  setSearch,
  setShowSignUp,
  removeSelectedProductsCategories
})

export const Store = pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(StorePage)
