import styled from 'styled-components'

export const RightWrapper = styled.div`
	display: flex;
	flex: 0;
	flex-direction: column;
	max-width: 100%;
	min-width: 100%;
	min-height: 100%;
`

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  font-size: .875rem;
  padding: 1.5rem;
	position: relative;

  > img.main {
    margin-bottom: 1.25rem;
    width: 100%;
    height: auto;
    max-height: 263px;
  }

  > h2 {
    margin-bottom: 1.25rem;
  }

	@media (min-width: 40rem) {
		min-width: 100%;
  	max-width: 100%;
		padding: 1rem;

		> div {
      height: 100%;
			&.left {
        height: 100%;
				img {
					min-width: 15rem;
					max-width: 15rem;
					top: -6rem;
				}

				h1 {
					margin: 1rem 0;
          padding: 0 1rem;
          display: block;
          box-sizing: border-box;
          width: 100%;
          text-align: center;
				}
			}
		}

		&.has-additionals {
			flex-direction: row;
      height: 100%;

			> div {
        height: 100%;
				@media (min-width: 40rem) {
					max-width: 50%;
					min-width: 50%;
				}

				&.left {
          position: relative;
          overflow-y: visible;
          overflow-x: visible;
           > div {
            justify-content: flex-start;
            align-items: center;
           }
					img {
						min-width: 15rem;
						max-width: 15rem;
					}
				}

				&.right {
					max-width: calc(50% - (1rem + .063rem));
					min-width: calc(50% - (1rem + .063rem));
					padding-left: 1rem;
					border-left: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
          height: 100%;
        }

				h1 {
					margin: 1rem 0 1rem -1rem;
				}

				p {
					margin-left: -1rem;
					padding-left: 1rem;
					padding-right: 1rem;
					box-sizing: border-box;
				}
			}
		}
	}
`

export const LeftWrapper = styled.div`
	display: flex;
	flex: 0;
	flex-direction: column;
  height: 100%;
	max-width: 100%;
	min-width: 100%;
	min-height: 100%;
	margin-bottom: 2rem;
	position: relative;

	@media (min-width: 40rem) {
		margin-bottom: 0;
	}
`

export const Textarea = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  height: 5rem;
  border-radius: .938rem;
  padding: .625rem;
  box-sizing: border-box;
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
  background: none;
`

export const Button = styled.button`
  display: flex;
  flex-direction: row;

  &.disabled {
    opacity: .4;
  }
`

export const CloseButton = styled.button`
  padding: 1rem;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
  text-align: end;
  position: absolute;
	right: 0;
	top: 0;
	font-size: 1.5rem;
	z-index: 1;

	@media (min-width: 40rem) {
		right: -1rem;
		top: -3.5rem;
	}
`

export const Image = styled.img`
  margin: 0;
`

export const H1 = styled.h1`
  margin: 0 0 .5rem 0;
  padding: 0;
  font-size: 1.25rem;
	line-height: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#333'};
  text-overflow: ellipsis;
  white-space: wrap;
  text-align: center;
  width: 100%;
  // @media (max-width: 40rem) {
  //   margin: 1.5rem 0 .5rem 0;
	// }
`

export const H4 = styled.h4`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#333'};
  text-align: center;
  border-top: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'};
  padding: 1rem 0 .5rem 0;
`

export const Div = styled.div``

export const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'} .063rem solid;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	min-width: 100%;
`

export const QuantityControlWrapper = styled.div``

export const Span = styled.span`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  font-size: .9rem;
  margin: 0 .5rem;
  font-weight: bold;

  @media(max-width: 60rem){
    font-size: .8rem;
  }
`

export const ButtonActions = styled.button`
  font-weight: bold;
  font-size: 1rem;
  border-radius: 50%;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  border: ${props => {
    if (props.qtd > 0) {
      return `1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'}`
    }
    return `1px solid #E2E2E2`
  }};
`

export const NotesWrapper = styled.div`
  min-width: 100%;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: .5rem;

  > h3 {
    margin-bottom: 1.25rem;
  }

  > textarea {
    margin-bottom: 1.25rem;
  }
  
  > ::-webkit-input-placeholder  { 
    font-size: .875rem;
    color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC'}; 
  }

  > input:-moz-placeholder { 
    font-size: .875rem;
    color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC'}; 
  }

  > textarea:-moz-placeholder { 
    font-size: .875rem;
    color:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC'}; 
  }

  > .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    > .quantity {
      border-radius: .625rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} .063rem solid;
      min-height: 2.638rem;
      box-sizing: border-box;
      padding: 0 .313rem;
      max-width: 35%;
      min-width: 35%;

      > button {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        font-weight: bold;
        font-size: 1.875rem;
        padding: 0 .313rem;
        text-decoration: none;
        background: none;
      }

      > span {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        font-weight: bold;
        font-size: 1rem;
      }      
    }

    > .add {
      flex: 1;
      max-width: 60%;
      min-width: 60%;

      > button {
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        border-radius: .625rem;
        min-width: 100%;
        display: flex;
        height: 2.638rem;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: .875rem;

        > span {
          flex: 1;
          color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff'};
        }
      }
    }
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .938rem;
	position: relative;
	min-width: 20rem;
	max-width: 20rem;

  > img {
    &.noimg{
      max-width: 30%;
      min-width: 30%;
      padding: 2rem;
    }

    flex: 1;
    max-width: 14rem;
		min-width: 14rem;
  }

	@media (min-width: 40rem) {
		&.main{
			min-height: 7rem;
		  max-height: 7rem;
		}

		&.noimg{
			max-height: 14rem;
			min-height: 14rem;

			>img{
				min-width: 11rem !important;
				max-width: 11rem !important;
			}

			${props => {
    if (!props.additionals) {
      return `
						max-height: 7rem;
						min-height: 7rem;

						>img{
							min-width: 8rem !important;
							max-width: 8rem !important;
						}
					`
    }
  }};
		}

		> img {
			flex: 1;
			min-width: 15rem;
			max-width: 15rem;

			${props => {
    if (props.image) {
      return `
						position: absolute;
						top: -5rem;
						left: calc(50% - 2rem);
						transform: translate(calc(-50% + 2rem), 0);
					`
    }
  }};

			@media (min-width: 40rem) {
				${props => {
    if (props.image) {
      return `
							top: -8rem;
						`
    }
  }};
			}
		}
	}
`

export const DetailBox = styled.div`
	max-width: 100%;
	min-width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
  height: 100%;
  > p {
    text-align: center;
    margin-bottom: 1rem;
    font-size: .738rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textdescriptionColor) || '#000'};

    &.description{
      font-size: 0.85rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textdescriptionColor) || '#000'};
      padding-left: 0rem;
      padding-right: 0rem;
      flex: initial;
      @media(min: 60rem){
        font-size: 1.25rem;
      }
    }

    &.from {
      font-size: 1rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.alterTextdescriptionColor) || '#000'};
      margin:0;
    }

    &.value {
      font-size: 1.25rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#000'};
      font-weight: bold;
			margin-bottom: 0;
    }

    &.promo {
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.positive) || '#333'};
      margin-bottom: 0;
      font-size: 1.125rem;
    }
  
    > span{
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333'};
      margin-bottom: 0;
      font-size: 1rem;
      text-decoration: line-through;
      margin-left: .313rem;
    }

		@media(min: 60rem){
			margin-bottom: 1.25rem;
		}
  }
`

export const AdditionalBox = styled.div`
	max-width: 100%;
	min-width: 100%;
	@media (min-width: 40rem) {
		max-width: unset;
		margin-left: -1rem;
		margin-right: -1rem;
    max-height: 250px;
    min-height: 190px;
		overflow: hidden;
		overflow-y: auto;
	}
`

export const TagWrapper = styled.div`
  display:flex;
  flex-direction: column;
  ${props => {
    if (props.itens && props.itens.length === 1) {
      return `margin-bottom: -3.5rem;`
    } else {
      return `margin-bottom: -5rem;`
    }
  }}
	margin-left: 0rem;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 1;

  @media(min-width: 40rem){
    left: -1rem;
		top: 0;
		transform: translate(-50%, 0);
  }
`

export const TagContentWrapper = styled.div`
  overflow: auto;
`; 

export const TagPointsWrapper = styled.div`
  display:flex;
  flex-direction: column;
  ${props => {
    if (props.itens && props.itens.length === 1) {
      return `margin-bottom: 0;`
    } else {
      return `margin-bottom: -1.2rem;`
    }
  }}
  ${props => {
    if (props.additionals && props.additionals.length > 0) {
      return `margin-left: 60%;`
    } else {
      return `margin-left: 80%;`
    }
  }}
	//margin-left: 0rem;
	//position: absolute;
	//left: 0;
	//top: 0;
	z-index: 1;

  @media(min-width: 40rem){
    // left: 85%;
		// top: 0;
		// transform: translate(-50%, 0);
  }
`
export const CustomImg = styled.img`
    min-width: 30px !important;
    max-width: 30px !important;
    top: none !important;
`
export const TagPoints = styled.div``

export const TagOne = styled.div``

export const TagTwo = styled.div``

export const LabelRectangular = styled.button`
  margin-bottom: 1rem;
  border-radius: .2rem;
  font-size: .8rem;
  background-color: ${props => (props.backgroundColor) || '#C2C2C2'};
  color: ${props => (props.fontColor) || '#000'};
	z-index: 1;
`

export const LabelCircularPoints = styled.button`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: .925rem;
  font-weight: bold;
  background-color: ${props => (props.backgroundColor) || '#C2C2C2'};
  color: ${props => (props.fontColor) || '#000'};
	z-index: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const LabelCircular = styled.button`
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: .725rem;
  background-color: ${props => (props.backgroundColor) || '#C2C2C2'};
  color: ${props => (props.fontColor) || '#000'};
	z-index: 1;
`
